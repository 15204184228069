<template>
  <div 
    class="m-field"
    :class="{ disabled, success, outlined, warning, light, dark, 'rounded': !tile }"
    @click="click"
  >
    <slot name="label">
      <span
        v-if="label!==undefined"
        class="m-field-label text-caption"
        :class="{ 'px-1': outlined }"
      >
        {{ label }}
      </span>
    </slot>
    <v-card
      flat
      :dark="dark"
      :light="light"
      class="m-field-container"
    >
      <div 
        class="m-field-content"
      >
        <slot default />
      </div>
    </v-card>
    <div 
      v-if="messages"
      class="m-field-messages text-caption pt-1"
      :class="{ 'px-3': outlined }"
    >
      <slot name="messages">
        <span
          :class="{ 'warning--text': warning }"
          class="m-field-message"
        >
          {{ messages }}
        </span>
      </slot>
    </div>
    <slot name="loading">
      <v-progress-linear
        v-show="loading"
        indeterminate
        absolute
        bottom
        height="3"
        :color="success ? 'success' : color"
        class="m-field-loading"
      />
    </slot>
  </div>
</template>

<style lang="scss">

#app .m-field {
  position: relative;
  background-color: transparent !important;
}
.m-field.light.outlined>.m-field-container {
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
}
.m-field .m-field-container {
  background-color: transparent !important;
  color: inherit !important;
  padding: 1px;
}
.m-field.dark.outlined>.m-field-container {
  border: 1px solid rgba(255, 255, 255, 0.24) !important;
}
.m-field.success {
  color: var(--v-success-base) !important;
}
.m-field.outlined.success>.m-field-container, .m-field.outlined.warning>.m-field-container {
  border: 2px solid !important;
  padding: 0 !important;
}
.m-field.warning {
  color: var(--v-warning-base) !important;
}

.m-field.disabled {
  opacity: .8;
  pointer-events: none;
}
.m-field .m-field-label {
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 1;
  margin-top: -9px;
  color: rgba(0, 0, 0, 0.6);
  background-color: var(--v-background-base);
  letter-spacing: normal !important;
}
.m-field.dark .m-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.m-field.disabled .m-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.m-field.success .m-field-label, .m-field.warning .m-field-label {
  color: inherit;
}

.m-field .m-field-messages {
  letter-spacing: normal !important;
}

.m-field .m-field-loading {
  margin-bottom: -2px;
}

</style>

<script>
  import {
    mdiCheckboxBlankOutline, 
    mdiCheckboxMarked,
  } from '@mdi/js'

  export default {
    props: {
      value: {
        type: [String, Array, Object, Number],
        default: undefined
      },
      label: {
        type: String,
        default: undefined
      },
      color: {
        type: String,
        default: 'primary'
      },
      hint: {
        type: String,
        default: undefined
      },
      rules: {
        type: Array,
        default: () => []
      },
      dark: {
        type: Boolean,
        default: undefined
      },
      light: {
        type: Boolean,
        default: undefined
      },
      outlined: {
        type: Boolean,
        default: true
      },
      tile: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      validate: {
        type: [Boolean, Function],
        default: false
      },
      success: {
        type: Boolean,
        default: undefined
      },
    },
    
    data: () => ({
      icons: {
        checked: mdiCheckboxMarked,
        unchecked: mdiCheckboxBlankOutline
      },
      controller: {
        value: null
      }
    }),

    computed: {
      messages () {
        const warning = this.warning;
        const hint = this.hint;

        return warning || hint;
      },
      warning () {
        const validate = _.isBoolean(this.validate) ? this.validate : this.validate(this.value);
        console.log(validate);
        const value = this.value;
        const warning = this.rules.length>0 && validate ? _.find(this.rules, (valid) => {
          return valid(value)!==true;
        }) : null;
        return _.isNil(warning) ? false : warning(value);
      }
    },

    watch: {
      
    },

    methods: {
      click (event) {
        this.$emit('click', event);
      }
    },
  }
</script>